import React from "react";
import parse from "html-react-parser";

import moment from "moment";
import * as utility from "../../utilities/utils";
import Popover from '../shared/controls/popover.component';
import ReactModalComponent from "../shared/controls/reactModal.component";
import * as dataService from "../../services/data.service";
import CreditCardNumberComponent from "../shared/creditCardNumber.component";
import ButtonComponent from "../shared/controls/button.component";
import Textbox from "../shared/textbox.component";
import PasswordTextbox from "../shared/passwordTextfield.component";
import smoothscroll from "smoothscroll-polyfill";
import history from "./../../history";


import {
  extractCardModel,
  formatCCNumber,
  formatMaskedCCNumber,
  isCCValid,
} from "./landing.component.utility";
import LibertyPopupComponent from "../shared/controls/libertyPopup.component";



var ndspmd = "0.0.0.0";
var timepOut;
export default class ConfirmLandingComponent extends React.Component    {
   constructor(props) {
      super(props);
      this.state = {
        isPasteEventFired: false,
        myCcNumber: "",
        showClickCount: 0,
        resetCcdetails: false,
        isDeleteOrBackspace: "",
        ccNumber: "",
        maskRequired: false,
        maskedCCNumber: "",
        cvv: "",
        ssn: "",
        cardType: "",
        ccType: "",
        ccFormat: "#### #### #### ####",
        ccMaxLength: "",
        ccMask: "0000 0000 0000 0000",
        cvvMsgTxt: "",
        cvvFormat: "",
        maskedCVV: "",
        unmaskedCVV: "",
        ccImgPath: "",
        cvvMaxLen: "",
        shouldDisplay: "none",
        name: "",
        number: "",
        DOB: "",
        dobValid: true,
        btnConfirmDisable: true,
        startSelection:"",
        endSelection:"",
        formErrors: {
          ccNumber: "",
          cvv: "",
          ssn: "",
          dob: "",
        },
        formData: {
          ccNumber: "",
          cvv: "",
          ssn: "",
          dob: "",
          eventID: this.props.eventID,
        },
        ccValid: true,
        cvvValid: false,
        handleShow: false,
        ssnValid: false,
        cvvLabel: "",
        errTxtCVV: "",
        language: "",
        utilityLabel1:  require("../resources/resources.en") ,
        cardModelDetails: extractCardModel('',utility.getParams().language, ''),
        counterConfirmClick: 0,
        incorrectAttemptCnt: 0,
        ccNumberOld: "",
        warningMsg: "",
        displayWarningMsg: false,
        isLoading: false,
        showPwd: false,
        isRender: true,
        sessionID: "",
        eventID: this.props.eventID,
        sessionTimeoutDisplay: this.props.sessionTimeoutDisplay,
        ivrPhone: "1-866-537-8424",
        productId: "GPCC",
        productType: "",
        errorCode: "",
        bestAction: "",
        switchPartner: "ON",
      };
      this.cursor="";
      this.params = utility.getParams();

    }

    componentDidMount() {
      smoothscroll.polyfill();
      if (utility.getPartner() ==="ChoiceConv" && this.props.switchVal.switchConfig === "ON"
          && this.params.language!== null && this.params.language!== undefined){
          history.push(`/?language=${this.params.language}&partner=choice`);
        }else if(utility.getPartner() ==="ChoiceConv" && this.props.switchVal.switchConfig === "ON"){
        history.push(`/?language=en&partner=choice`);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
      const queryString = require("query-string");
      let productType = utility.getPartner();

      const language = this.props.history.location.data
        ? this.props.history.location.data.language
        : queryString.parse(location.search).language;

      let utilityLabel1 = utility.findUtilityLabelFromProduct(productType, language);

      let ivrNumber = this.props.switchVal.ivrPhoneNumber ? this.props.switchVal.ivrPhoneNumber : this.state.ivrPhoneNumber
      this.setState({
        sessionID: this.props.sessionID,
        eventID: this.props.eventID,
        language: language || utility.getQString(),
        entryPoint: utility.getEntryType(),
        utilityLabel1:utilityLabel1,
        warningMsg: this.props.history.location.data
          ? utilityLabel1.utilityLabel.ecaErrorMessage1
          : "",
        displayWarningMsg: this.props.history.location.data
          ? this.props.history.location.data.displayWarningMsg
          : false,
        productType: productType,
        ivrPhoneNumber: ivrNumber
      });
    }

    isValidData(data){
      return data? "" : "validate-error"
    }
    handleMouseDown = (e) => {
      e.preventDefault();
    };
    handleOnFocus = (e) => {
      this.setState({
        ccNumberOld: this.state.ccNumber,
        displayWarningMsg: false,
      }, () => {
        this.props.callbackTogetChildData(this.state.isLoading,this.state.displayWarningMsg,this.state.sessionTimeoutDisplay)
      });
    };
    handleOnClick= (e) => {
      this.setState({
        startSelection:e.target.selectionStart,
        endSelection:e.target.selectionEnd,
      })
    };
    handleKeyDown = (event) => {
      const regEx = /^[0-9\b]+$/
      if (!(event.key === '' || regEx.test(event.key))) {
        if (!event.ctrlKey && !((event.key === "Enter") || (event.key === "Backspace") || (event.key === "Delete") || (event.key === "Tab"))) {
          event.preventDefault();
        }
      }
      if (event.key === "Delete" || event.key === "Backspace") {
        this.setState({ isDeleteOrBackspace: event.key })
      }
      else {
        this.setState({ isDeleteOrBackspace: "" })
      }
    };

    getSwitchInfo = (eventID, partner, sessionID) => {
      let switchData;
      dataService.switchInfo(eventID, partner, sessionID).then(switchRes => {
        if (switchRes !== null && switchRes !== undefined) {
          switchData = switchRes.data
          if (switchData.switchConfig === "OFF") {
            this.setState({
              ivrPhone: switchData.ivrPhoneNumber,
              switchPartner: "OFF"
            }, () => {
              this.setConfirmCardResponse("UNKNOWN", this.state.ivrPhone);
            })
          }
          else {
            this.setState({
              ivrPhone: switchData.ivrPhoneNumber,
              switchPartner: "ON"
            })
          }
        }
      }).catch(err => {
        this.setState({
          ivrPhone: '1-866-537-8424',
          switchPartner: "ON"
        })
      })
    }

    handleOnchange = (event) => {
      if (this.state.isPasteEventFired) {
        this.handlePasteEventFired()
        return false
      }
      let currentCc_Number = ""
      const currentCcnumber = event.target.value;
      const currentCcNumberLength = currentCcnumber.replace(/\s/g, "").length
      let beforeCorsor = ""
      let afterCorsor = ""
      let currentPosition = event.target.selectionStart;
      let numberOfSpaces = event.target.value.substring(0, currentPosition).split(" ").length - 1
      let index = currentPosition - numberOfSpaces
      if (this.state.isDeleteOrBackspace === "") {
        const noSpaceCC = event.target.value.replace(/\s/g, "")
        const currentChar = noSpaceCC.substring(index - 1, index)
        if (index === 1) {
          let beforeCorsor = currentChar
          let afterCorsor = this.state.myCcNumber
          currentCc_Number = beforeCorsor + afterCorsor
        }
        else if (index - 1 === this.state.myCcNumber.length) {
          beforeCorsor = this.state.myCcNumber
          afterCorsor = currentChar
          currentCc_Number = beforeCorsor + afterCorsor
        }
        else {
          if (this.state.myCcNumber.length === 0) {
            this.state.myCcNumber = event.target.value;
            currentCc_Number = this.state.myCcNumber
          } else {
            beforeCorsor = this.state.myCcNumber.slice(0, index - 1)
            afterCorsor = this.state.myCcNumber.slice(index - 1, this.state.myCcNumber.length)
            currentCc_Number = beforeCorsor + currentChar + afterCorsor;
          }
        }
      }
      if (this.state.isDeleteOrBackspace !== "") {
        currentCc_Number=this.handleDeleteMethod(index,currentCcnumber,currentCcNumberLength);
       }


      const cardModelDetails = extractCardModel(
        currentCc_Number,
        this.props.language,
        this.state.productType
      );
      if(cardModelDetails.ccMaxLength !== this.state.ccNumber.replace(/\s/g, "").length){
        this.setState({
        maskedCVV: "",
        cvv: "",
        ssn: "",
        DOB: "",
        dobValid: true,
        cvvValid: false,
        sessionTimeoutDisplay: false,
        ssnValid: false,
        formErrors: {
        ccNumber: "",
        cvv: "",
        ssn: "",
        dob: "",
        },
        })
        }

      this.setState({
        ccMaxLength: cardModelDetails.ccMaxLength,
        cardModelDetails: cardModelDetails,
        ccFormat: cardModelDetails.maskedCCNumber,
        displayWarningMsg: false,
      },() => {
        this.props.callbackTogetChildData(this.state.isLoading,this.state.displayWarningMsg,this.state.sessionTimeoutDisplay)
      });

      const newValue = event.target.value;
      const newValueLengthWithoutSpaces = newValue.replace(/\s/g, "").length
      const formatLengthWithoutSpaces = cardModelDetails.maskedCCNumber.replace(/\s/g, "").length
      if (newValueLengthWithoutSpaces > formatLengthWithoutSpaces && this.state.isDeleteOrBackspace==="") {
        return;
      }
      this.handleMyCcNumber(currentCc_Number)
      let vFormatCCNumber = ""
      if (!this.state.showPwd) {
        const maskedCCNumber = event.target.value.replace(/\d/gi, "●")
        vFormatCCNumber = formatMaskedCCNumber(maskedCCNumber, this.state.productType, currentCc_Number)
      }
      else {
        vFormatCCNumber = formatCCNumber(event.target.value, this.state.productType)
      }
      this.setState(
        {
          ccNumber: vFormatCCNumber,
          formatCCNumber: formatCCNumber(
            event.target.value,
            this.state.productType
          ),
        },
        () => {
        this.IsConfirmDisabled();
        if (this.state.ccNumber.length > 0){
          this.setState({ sessionTimeoutDisplay: true }, () => {
            this.props.callbackTogetChildData(this.state.isLoading,this.state.displayWarningMsg,this.state.sessionTimeoutDisplay)
          });
        } else this.setState({ sessionTimeoutDisplay: false });
        }
      );

      if (this.checkCCNumberByPartner()) {
        if (
          currentCc_Number.replace(/\s/g, "").length === 6 && (currentCc_Number.trim().includes("455328") || currentCc_Number.trim().includes("4553 28")))
           {
          this.setState({ productId: "HCOM", productType: "hcom" }, () => {
            this.getSwitchInfo(this.state.eventID, this.state.productType, this.props.sessionID)
          });
        } else if (
          currentCc_Number.replace(/\s/g, "").length >= 6 &&
          !currentCc_Number.trim().includes("455328")  && this.props.switchVal.switchConfig === "OFF"
        ) {
          this.setState({
            productId: "GPCC",
            productType: "gpcc",
            ivrPhone: this.props.switchVal.ivrPhoneNumber,
            switchPartner: "OFF"
          }, () => {
            this.setConfirmCardResponse("UNKNOWN", this.state.ivrPhone);
          })
        } else if (currentCc_Number.replace(/\s/g, "").length >= 6 &&
        !currentCc_Number.trim().includes("455328") ) {
        this.setState({ productId: "GPCC", productType: "gpcc" })
      }
      }

    };
    handleMyCcNumber = (pMyCcNumber) => {
      if (pMyCcNumber.length === 1 || pMyCcNumber.length <= this.state.ccMaxLength || !/\s/.test(pMyCcNumber)) {
        this.setState({ myCcNumber: pMyCcNumber })
        this.setState({ ccNumber: pMyCcNumber })
      }
    }
    IsConfirmDisabled = () => {
      if (
        this.state.ccNumber.replace(/\s/g, "").length === this.state.cardModelDetails.ccMaxLength &&
        this.state.cvv.trim().length === this.state.cardModelDetails.cvvMaxLen &&
        this.state.ssn.trim().length === 4 &&
        this.state.DOB.trim().length === 10 &&
        this.state.dobValid
      ) {
        this.setState({ btnConfirmDisable: false });

      } else {
        this.setState({ btnConfirmDisable: true });
      }
    };

    handleOnBlurCCNumber = (value) => {
      this.setState(
        {
          ccValid: isCCValid(value, this.state.cardModelDetails.ccMaxLength),
          displayWarningMsg: false,
        },
        () => {
          this.IsConfirmDisabled();
          if (this.state.showPwd) {
            this.handleClickShowPassword()
          }
          this.props.callbackTogetChildData(this.state.isLoading,this.state.displayWarningMsg,this.state.sessionTimeoutDisplay)
        }
      );
      if (this.state.ccNumber.replace(/\s/g, "").length === this.state.cardModelDetails.ccMaxLength) {
        try {
          var ndsData = window.ndsapi;
          ndsData.bindNewFields();
        } catch (error) {
          var eventID=this.props.eventID
          var sessionID=this.props.sessionID
          this.getNuDataLogs(eventID,sessionID,error)
        }
      }
    };
    getNuDataLogs(eventID, sessionID, error){
      dataService
          .getNudataLogs(eventID, sessionID, error)
          .then((res) => {
            if (res !== null && res !== undefined) {
             console.log("NuData Logs = ",res)
            }
          })
          .catch((err) => {
             console.log("Exception or Error",err)
          });
    }

    handleChangeCVV = (event) => {
      clearTimeout(timepOut);
      if (this.state.showPwd) {
        this.handleClickShowPassword()
      }
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState(
          {
            cvv: event.target.value,
          },
          () => {
            this.IsConfirmDisabled();
            if (
              this.state.cvv.trim().length ===
              this.state.cardModelDetails.cvvMaxLen
            ) {
              this.setState({ cvvValid: true });
            } else {
              this.setState({ cvvValid: false });
            }
          }
        );
      }
    };
    handleDOBChange = (type, value) => {
      clearTimeout(timepOut);
      const formErrors = this.state.formErrors;
      this.setState(
        {
          DOB: value,
          dobValid: this.isValidDOB("change", value),
          formErrors: {
            ...formErrors,
            dob: this.isValidDOB("change", value)
              ? ""
              : this.state.utilityLabel1.utilityLabel.errValidationDOB.dob,
          },
          maskRequired: true,
        },
        () => {
          this.IsConfirmDisabled();
          if (this.state.showPwd) {
            this.handleClickShowPassword()
          }
        }
      );
    };
    isValidDOB = (action, value) => {
      let isValidDob = true;
      let date = "";
      const dobValue = value.toString().trim();
      const month = dobValue.split("/")[0].trim();
      if (dobValue !== "") {
        date = dobValue.split("/")[1].trim();
      }

      if (month.length === 1 && month > 1) isValidDob = false;
      if (month.length === 2 && (month < 1 || month > 12)) isValidDob = false;
      if (date.length === 1 && date > 1) isValidDob = false;
      if (date.length === 2 && (date < 1 || date > 31)) isValidDob = false;
      if (this.isFutureDate(dobValue)) isValidDob = false;
      if (!this.isValidDate(dobValue)) isValidDob = false;
      if (dobValue.length !== 10 && action === "blur") isValidDob = false;
      return isValidDob;
    };

    isFutureDate(idate) {
      var today = new Date().getTime();
      idate = idate.split("/");
      idate = new Date(idate[2], idate[0] - 1, idate[1]).getTime();
      return today - idate < 0 ? true : false;
    }

    isValidDate(date) {
      var newDate = null;
      var isValid = true;
      if (date !== undefined) {
        var splittedDate = date.split("/");
        if (splittedDate.length === 3) {
          newDate = moment(
            splittedDate[2] + "-" + splittedDate[0] + "-" + splittedDate[1], moment.ISO_8601
          );
        }
        if (newDate !== null) isValid = newDate.isValid();
      }
      return isValid;
    }
    handleDeleteMethod =(index,currentCcnumber,currentCcNumberLength) =>{
      let currentCc_Number="";
      let beforeCorsor="";
      let afterCorsor="";
      let deletedDigits=this.state.endSelection-this.state.startSelection;
        if(deletedDigits===undefined||this.state.startSelection===this.state.endSelection){
          deletedDigits=1
        }
        if (index === 0) {
          if (currentCcnumber !== "") {
            afterCorsor = this.state.myCcNumber.slice(index + deletedDigits, this.state.myCcNumber.length)
            currentCc_Number = afterCorsor
          }
        }
        else if (index === this.state.myCcNumber.length - 1) {
          beforeCorsor = this.state.myCcNumber.slice(0, index)
          currentCc_Number = beforeCorsor
        }
        else if (currentCcNumberLength === this.state.ccNumber.replace(/\s/g, "").length) {
          currentCc_Number = this.state.ccNumber
        }
        else {
          let position=index+deletedDigits;
          beforeCorsor = this.state.myCcNumber.slice(0, index)
          afterCorsor = this.state.myCcNumber.slice(position, this.state.myCcNumber.length)
          currentCc_Number = beforeCorsor + afterCorsor
        }
        return currentCc_Number
    }

    handleChangeSSN = (event) => {
      clearTimeout(timepOut);
      this.setState({ maskRequired: true, /*showPwd: false */ });
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState(
          {
            ssn: event.target.value,
          },
          () => {
            if (this.state.showPwd) {
              this.handleClickShowPassword()
            }
            this.IsConfirmDisabled();
            if (this.state.ssn.trim().length === 4) {
              this.setState({ ssnValid: true });
            } else {
              this.setState({ ssnValid: false });
            }
          }
        );
      }
    };

    handleOnBlurDOB = (value) => {
      clearTimeout(timepOut);
      const formErrors = this.state.formErrors;
      this.setState(
        {
          dobValid: this.isValidDOB("blur", value),
          formErrors: {
            ...formErrors,
            dob: this.isValidDOB("blur", value)
              ? ""
              : this.state.utilityLabel1.utilityLabel.errValidationDOB.dob,
          },
        },
        () => {
          this.IsConfirmDisabled();
          if (this.state.showPwd) {
            this.handleClickShowPassword()
          }
        }
      );
    };

    handleOnBlurCVV = (event) => {
      this.setState(
        {
          cvv: event.target.value,
        },
        () => {
          if (this.state.showPwd) {
            this.handleClickShowPassword()
          }
          if (
            event.target.value === "" ||
            event.target.value.trim().length ===
            this.state.cardModelDetails.cvvMaxLen
          ) {
            this.setState({ cvvValid: true });
          } else this.setState({ cvvValid: false });
          let fieldValidationErrors = this.state.formErrors;
          let cvvValid =
            event.target.value.length === this.state.cardModelDetails.cvvMaxLen;
          fieldValidationErrors.cvv = cvvValid
            ? ""
            : parse(this.state.cardModelDetails.errTxtCVV);
          this.setState({ formErrors: fieldValidationErrors });
        }
      );
    };

    handleOnBlurSSN = (event) => {
      clearTimeout(timepOut);
      this.setState(
        {
          ssn: event.target.value,
        },
        () => {
          if (this.state.showPwd) {
            this.handleClickShowPassword()
          }
          if (this.state.ssn === "" || this.state.ssn.trim().length === 4) {
            this.setState({ ssnValid: true });
          } else {
            document.getElementById("ssn")?.focus();
            this.setState({ ssnValid: false });
          }
          let fieldValidationErrors = this.state.formErrors;
          let ssnValid = event.target.value.length === 4;
          fieldValidationErrors.ssn = ssnValid
            ? ""
            : parse(this.state.utilityLabel1.utilityLabel.errValidationSSN);
          this.setState({ formErrors: fieldValidationErrors });
        }
      );
      document.getElementById("confirm")?.focus();
    };

    cancelOnClick = () => {

      this.setState({ handleShow: true, displayWarningMsg: false },
        () => {
          this.props.callbackTogetChildData(this.state.isLoading,this.state.displayWarningMsg,this.state.sessionTimeoutDisplay)
        });
      dataService
        .trackEvent(
          "Landing",
          "Cancel",
          this.state.sessionID
        )
        .then();
    };


    confirmOnClick = () => {
      this.setState({ eventID: utility.getUniqueID() });
      this.getCardConfirmInfo();
    };
    trackEvents = (page, event, sessionID) => {
      dataService
        .trackEvent(
          page,
          event,
          sessionID ? this.props.sessionID : sessionID
        )
        .then(() => {
          if (event === "SignOn") {
            window.location.replace(
              this.state.utilityLabel1.utilityLabel.hrefSignOn
            );
          } else if (event === "Enroll") {
            window.location.replace(
              this.state.utilityLabel1.utilityLabel.hrefEnroll
            );
          }
        });
    };

    getCardConfirmInfo = () => {
      this.setState(
        { counterConfirmClick: this.state.counterConfirmClick + 1 },
        () => {
          if (this.state.counterConfirmClick < 4) {
            if (this.state.ccNumber === this.state.ccNumberOld) {
              this.getConfirmCardService();
              this.setState(
                { incorrectAttemptCnt: this.state.incorrectAttemptCnt + 1 },
                () => {
                  if (this.state.incorrectAttemptCnt >= 3) {
                    this.setState({
                      displayWarningMsg: true,
                      warningMsg:
                        this.state.utilityLabel1.utilityLabel
                          .warningMsgforFailedAttempt,
                    },() => {
                      this.props.callbackTogetChildData(this.state.isLoading, this.state.displayWarningMsg,this.state.sessionTimeoutDisplay)
                    });
                    this.setState({ incorrectAttemptCnt: 1 });
                    this.resetState();
                  }
                }
              );
            } else {
              this.setState({ incorrectAttemptCnt: 1 });
              this.setState({ ccNumberOld: this.state.ccNumber });
              this.getConfirmCardService();
            }
          } else {
            this.setConfirmCardResponse("FAIL", this.state.ivrPhone);
            this.setState({ isLoading: false });
          }
        }
      );
    };

    getConfirmCardService = () => {
      let formData = this.state.formData;
      this.setState({ isLoading: true },() => {
        this.props.callbackTogetChildData(this.state.isLoading,this.state.displayWarningMsg,this.state.sessionTimeoutDisplay)
      });

      if (this.ValidateFormData(formData)) {

        dataService
          .postCardConfirmationInfo(
            this.state.formData,
            ndspmd,
            this.props.sessionID,
            this.state.productType
          )
          .then((res) => {
            if (res !== null && res !== undefined) {
              this.setState({ isLoading: false }, () => {
                this.props.callbackTogetChildData(this.state.isLoading, this.state.displayWarningMsg, this.state.sessionTimeoutDisplay)
              });
              this.setConfirmCardResponse(res.data.confirmCardResponse.status,
                res.data.confirmCardResponse.ivrPhoneNumber,res.data.confirmCardResponse.errorCode, res.data.confirmCardResponse.bestAction);
            }
          })
          .catch((err) => {
            this.setState({ isLoading: false },() => {
              this.props.callbackTogetChildData(this.state.isLoading, this.state.displayWarningMsg, this.state.sessionTimeoutDisplay)
            });
            this.setConfirmCardResponse("UNKNOWN", this.state.ivrPhone);
          });
      }
    };
    resetState = () => {
      this.setState({
        maskedCVV: "",
        cvv: "",
        ssn: "",
        DOB: "",
        dobValid: true,
        maskedCCNumber: "",
        ccNumber: "",
        incorrectAttemptCnt: 0,
        ccNumberOld: "",
        btnConfirmDisable: true,
        sessionTimeoutDisplay: false,
        formErrors: {
          ccNumber: "",
          cvv: "",
          ssn: "",
          dob: "",
        },
      });
    };
    ValidateFormData = (formData) => {
      let vFormatCCNumber = formatCCNumber(this.state.myCcNumber, this.state.productType)
      formData.ccNumber = vFormatCCNumber;
      formData.ssn = this.state.ssn;
      formData.cvv = this.state.cvv;
      formData.dob = this.state.DOB;
      formData.eventID = this.state.eventID;
      this.setState({
        formData: formData,
      });

      if (
        formData.ccNumber !== null &&
        formData.ccNumber !== "" &&
        formData.cvv !== null &&
        formData.cvv !== "" &&
        formData.dob !== null &&
        formData.dob !== "" &&
        formData.ssn !== null &&
        formData.ssn !== ""
      ) {
        if (
          formData.ccNumber.replace(/\s/g, "").length ===
          this.state.cardModelDetails.ccMaxLength &&
          formData.cvv.trim().length === this.state.cardModelDetails.cvvMaxLen &&
          formData.ssn.trim().length === 4 &&
          formData.dob.trim().length === 10
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    setConfirmCardResponse = (res, ivrPhoneNumber, errorCode, bestAction) => {
      try {
        var nds = window.ndsapi;
        nds.reinit("EnhancedCardActivation", 1);
      } catch (error) {
       var eventID=this.props.eventID
       var sessionID=this.props.sessionID
        this.getNuDataLogs(eventID,sessionID,error)
      }
      let prodType = this.params.partner;

      if (res === "SUCCESS") {
        let prodTypeUrl = `/?language=${this.props.language}&entrypoint=${this.props.entryPoint}&page=thankyou&partner=${prodType}`;
        if (this.params?.lob === "business-cards") {
          prodType = "rubicon";
          prodTypeUrl = `/?language=${this.props.language}&entrypoint=${this.props.entryPoint}&page=thankyou&lob=business-cards`;
        }
        history.push({
          pathname: prodType
            ? prodTypeUrl
            : `/?language=${this.props.language}&entrypoint=${this.props.entryPoint}&page=thankyou`,
          detail: {
            last4DigitCC: this.state.myCcNumber.substr(-4),
            language: this.props.language,
            type: "SUCCESS",
            entryPoint: this.props.entryPoint,
            productType: this.state.productType,
            sessionID: this.props.sessionID,
            bestAction: bestAction ? bestAction.url : '',
            bestActionLabel: bestAction ? bestAction.label : ''
          },
        });
      } else if (res === "UNKNOWN") {
        let prodTypeUrl = `/?language=${this.props.language}&entrypoint=${this.props.entryPoint}&page=sorry&partner=${prodType}`;
        if (this.params?.lob === "business-cards") {
          prodType = "rubicon";
          prodTypeUrl = `/?language=${this.props.language}&entrypoint=${this.props.entryPoint}&page=sorry&lob=business-cards`;
        }
        history.push({
          pathname: prodType
            ? prodTypeUrl
            : `/?language=${this.props.language}&entrypoint=${this.props.entryPoint}&page=sorry`,
          detail: {
            last4DigitCC: this.state.myCcNumber.substr(-4),
            language: this.props.language,
            type: "UNKNOWN",
            entryPoint: this.props.entryPoint,
            productType: this.props.productType,
            sessionID: this.props.sessionID,
            eventID: this.props.eventID,
            ivrPhoneNumber: ivrPhoneNumber
          },
        });
      } else if (res === "FAIL" || res === "FRAUD") {
        let prodTypeUrl = `/?language=${this.state.language}&entrypoint=${this.state.entryPoint}&page=sorry&partner=${prodType}`;
        if (this.params?.lob === "business-cards") {
          prodType = "rubicon";
          prodTypeUrl = `/?language=${this.state.language}&entrypoint=${this.state.entryPoint}&page=sorry&lob=business-cards`;
        }
        history.push({
          pathname: prodType
            ? prodTypeUrl
            : `/?language=${this.state.language}&entrypoint=${this.state.entryPoint}&page=sorry`,
          detail: {
            last4DigitCC: this.state.myCcNumber.substr(-4),
            language: this.state.language,
            type: "FAIL",
            entryPoint: this.state.entryPoint,
            productType: this.state.productType,
  		      ivrPhoneNumber: this.state.ivrPhoneNumber,
            errorCode: errorCode,
            sessionID: this.props.sessionID
          },
        });
      } else if (res === "WARNING") {
        window.scrollTo({ top: 0, behavior: "smooth" });
        document.documentElement.scrollTo(0, 0);
        this.resetState();
        this.setState({
          displayWarningMsg: true,
          warningMsg:
            this.state.utilityLabel1.utilityLabel.warningMsgforFailedAttempt,
        },() => {
          this.props.callbackTogetChildData(this.state.isLoading, this.state.displayWarningMsg, this.state.sessionTimeoutDisplay)
        });
      }
      this.setState({myCcNumber:""});
    };
    handleClickShowPassword = () => {
      let currentState = {}
      if (this.state.showPwd) {
        const maskedCcNumber = this.state.ccNumber.replace(/\d/gi, "●")
        const vFormattedNum = formatMaskedCCNumber(maskedCcNumber, this.state.productType, this.state.myCcNumber)
        currentState = { ccNumber: vFormattedNum, showPwd: !this.state.showPwd, showClickCount: this.state.showClickCount + 1 }
      }
      else {
        const vFormattedNum = formatCCNumber(this.state.myCcNumber, this.state.productType)
        currentState = { ccNumber: vFormattedNum, showPwd: !this.state.showPwd, showClickCount: this.state.showClickCount + 1 }
      }
      this.setState(currentState, () => {
        timepOut = setTimeout(this.handleMaskingtimer, 50000);
        clearTimeout(timepOut)
        dataService
          .trackEvent(
            "Landing",
            this.state.showPwd ? "UnMask" : "Mask",
            this.props.sessionID
          )
          .then();
      });
    };


    handleMaskingtimer = () => {
      if (this.state.showPwd) {
        this.handleClickShowPassword()
      }
    };

   checkforFusionStyle = () => {
     if( this.props.productType === 'gpcc' ||  this.props.productType === 'rubicon'  || this.props.productType === 'onekey' || this.props.productType === 'onekeyplus'){
          return true;
      }
      else if(this.props.productType !== 'gpcc' && (this.state.ccNumber.replace(/\s/g, "").length !== this.state.cardModelDetails.ccMaxLength)){
          return false;
      }else{
          return true;
      }
   }

   checkforCardImage = () =>{
    if( this.props.productType === 'gpcc' || this.props.productType === 'rubicon' || this.props.productType === 'onekey' || this.props.productType === 'onekeyplus'){
      return false;
    }
   else{
      return true;
    }
   }

   checkforPartner = () =>{
    if( this.props.productType === 'gpcc' || this.props.productType === 'rubicon' || this.props.productType === 'onekey' || this.props.productType === 'onekeyplus'){
      return true;
    }
   else{
      return false;
    }
   }

   checkCCNumberByPartner = () =>{
   if(this.state.productType === "gpcc" || this.state.productType === "hcom"){
      return true;
    }
   else{
      return false;
    }
   }

    handleOnPaste = (event) => {
      let copiedValue = event.clipboardData.getData("text");
      copiedValue = copiedValue.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
      const cardModelDetails = extractCardModel(
        copiedValue,
        this.state.language,
        this.state.productType
      );
      if(cardModelDetails.ccMaxLength !== this.state.ccNumber.replace(/\s/g, "").length){
         this.setState({
          maskedCVV: "",
          cvv: "",
          ssn: "",
          DOB: "",
          dobValid: true,
          cvvValid: false,
          sessionTimeoutDisplay: false,
          ssnValid: false,
          formErrors: {
            ccNumber: "",
            cvv: "",
            ssn: "",
            dob: "",
          },
        })
      }


      this.setState({
        ccMaxLength: cardModelDetails.ccMaxLength,
        cardModelDetails: cardModelDetails,
        ccFormat: cardModelDetails.maskedCCNumber,
        displayWarningMsg: false,
        myCcNumber: copiedValue
      }, () => {
        let vFormatCCNumber = ""
        if (!this.state.showPwd) {
          const maskedValue = copiedValue.replace(/\d/gi, "●")
          vFormatCCNumber = formatMaskedCCNumber(maskedValue, this.state.productType, copiedValue)
        }
        else {
          vFormatCCNumber = formatCCNumber(copiedValue, this.state.productType)
        }
        this.setState(
          {
            ccNumber: vFormatCCNumber,
            formatCCNumber: formatCCNumber(
              copiedValue,
              this.state.productType
            ),
          },
          () => {
            this.IsConfirmDisabled();
            if (this.state.ccNumber.length > 0)
              this.setState({ sessionTimeoutDisplay: true }, () => { });
            else this.setState({ sessionTimeoutDisplay: false });
            if (this.checkCCNumberByPartner()) {
              if (
                copiedValue.replace(/\s/g, "").length >= 6 &&
                (copiedValue.trim().includes("455328") || copiedValue.trim().includes("4553 28"))
              ) {
                this.setState({ productId: "HCOM", productType: "hcom" }, () => {
                  this.getSwitchInfo(this.state.eventID, this.state.productType, this.props.sessionID)
                });
              }
             else if (
                copiedValue.replace(/\s/g, "").length >= 6 &&
                !copiedValue.trim().includes("4553 28") && this.props.switchVal.switchConfig === "OFF"
              ) {
                this.setState({
                  productId: "GPCC",
                  productType: "gpcc",
                  ivrPhone: this.props.switchVal.ivrPhoneNumber,
                  switchPartner: "OFF"
                }, () => {
                  this.setConfirmCardResponse("UNKNOWN", this.state.ivrPhone);
                })
              } else if (copiedValue.replace(/\s/g, "").length >= 6 &&
                !copiedValue.trim().includes("4553 28")) {
                this.setState({ productId: "GPCC", productType: "gpcc" })
              }
            }
          });
      }
      );
      event.target.value = copiedValue
      this.setState({ isPasteEventFired: true })
    };

    handlePasteEventFired = () => {
      this.setState({ isPasteEventFired: false })
    }
    onFocusInputField = () => {
      if (this.state.showPwd) {
        this.handleClickShowPassword()
      }  };
render(){
    var nds = document.getElementsByName("nds-pmd");
    for (var i = 0; i < nds.length; i++){
        ndspmd = nds[0].defaultValue;
    }
    return (<>
       <div className="row m-0">
                            <div className= { this.checkforPartner()
                ? "col-12 col-lg-8 offset-lg-2 py-3 px-1" : "col-12 col-sm-7 col-md-8 col-lg-7 pb-4 px-1"}>
                              <CreditCardNumberComponent
                                language={this.props.language}
                                inputLabel={
                                  this.state.utilityLabel1.utilityLabel.inputLabelCC
                                }
                                resetCcdetails={this.state.resetCcdetails}
                                isDeleteOrBackspace={this.state.isDeleteOrBackspace}
                                showClickCount={this.state.showClickCount}
                                myCcNumber={this.state.myCcNumber}
                                ccFormat={this.state.cvvFormat}
                                handleMyCcNumber={this.handleMyCcNumber}
                                handleKeyDown={this.handleKeyDown}
                                handleChange={this.handleOnchange}
                                onBlurInput={this.handleOnBlurCCNumber}
                                onFocusInput={this.handleOnFocus}
                                onClickInput={this.handleOnClick}
                                handleClickShowPassword={this.handleClickShowPassword}
                                handlePaste={this.handleOnPaste}
                                handlePasteEventFired={this.handlePasteEventFired}
                                isPasteEventFired={this.state.isPasteEventFired}
                                showPwd={this.state.showPwd}
                                productType={this.props.productType}
                                idName="ccNumber"
                                inputType="password"
                                errMsg={parse(
                                  this.state.utilityLabel1.utilityLabel
                                    .errValidationCCNumber
                                )}
                                value={this.state.ccNumber}
                                maxLength={this.state.cardModelDetails.ccMaxLength}
                                helperText={parse(
                                  this.state.utilityLabel1.utilityLabel
                                    .errValidationCCNumber
                                )}
                                ariaDscBy={
                                  this.state.ccValid
                                    ? "eca-confirm-ccno-help"
                                    : "eca-confirm-ccno-help ccNumber-helper-text"
                                }
                                ccValid={this.state.ccValid}
                              ></CreditCardNumberComponent>
                              <div
                                className="form-text field-help"
                                id="eca-confirm-ccno-help"
                              > {this.props.productType === "gpcc"  || this.props.productType === "onekey"  || this.props.productType === "onekeyplus"  ?
                                parse(
                                  this.state.utilityLabel1.utilityLabel
                                    .lblCCMsgFusionContent
                                ):  parse(
                                  this.state.utilityLabel1.utilityLabel
                                    .lblCCMsgContent
                                )
                                }
                              </div>
              </div>
            </div>
            <div className={this.checkforFusionStyle() ? "row m-0 eca-confirm-cvv-container" : "row m-0 eca-confirm-cvv-container displayNoneDiv"}>
              {this.checkforCardImage() ?
                (<div className="col-12 col-xl-5 pb-3 order-first order-xl-last">
                  <figure className="mb-0">
                    <img src={this.state.cardModelDetails.ccImgPath} className="img-fluid" id="imgCreditCard" style={{ width: "100%", maxWidth: "180px" }} alt="" />
                    <figcaption className="pt-1 font-sans-semibold" aria-hidden="true">
                      {this.state.cardModelDetails.imgCaption}
                    </figcaption>
                  </figure>
                </div>) : null}
                            <div className= {this.checkforPartner() ? "col-12 col-lg-8 offset-lg-2 py-3 px-1":"col-12 col-sm-7 col-md-8 col-lg-7 pb-3 px-1"}>
                <PasswordTextbox
                                inputLabel={this.checkforPartner() ? this.state.cardModelDetails.cvvFusionLabel : this.state.cardModelDetails.cvvLabel}
                                inputMaxLength={this.state.cardModelDetails.cvvMaxLen}
                                onKeyDownInput={this.handleKeyDown}
                                inputType="password"
                                field={"cvv"}
                                handleChangeInput={this.handleChangeCVV}
                                value={this.state.cvv}
                                idName={
                                  this.state.utilityLabel1.utilityLabel.idCVVName
                                }
                                clsName={`${this.isValidData(this.state.cvvValid)
                                  }`}
                                errMsg={this.state.formErrors}
                                onBlurInput={this.handleOnBlurCVV}
                                onFocusInputField={this.onFocusInputField}
                                ariaDscBy={
                                  this.state.formErrors.cvv.length === 0
                                    ? "eca-confirm-cvv-help"
                                    : "eca-confirm-cvv-help cvv-helper-text"
                                }
                ></PasswordTextbox>
                              <div
                                className="form-text m-0 field-help"
                                id="eca-confirm-cvv-help"
                              >
                               {(this.props.productType === "bilt" || this.props.productType === "choice") ?
                              (parse(this.state.cardModelDetails.cvvMsgTxt)):(<Popover productType={this.props.productType} language={this.props.language} ></Popover>)
                              }
                </div>
              </div>
            </div>

              <div
                            className={
                              this.checkforFusionStyle()
                              ? "row m-0 eca-confirm-dobssn-container"
                              : "row m-0 eca-confirm-dobssn-container displayNoneDiv"
                            }
                          >
                            <div className= {this.checkforPartner() ? "col-12 col-lg-8 offset-lg-2 py-3 px-1":"col-12 col-sm-7 col-md-8 col-lg-7 pb-3 px-1"}>
                              <Textbox
                                inputLabel={
                                  this.state.utilityLabel1.utilityLabel.inputLabelDOB
                                }
                                field={"dob"}
                                handleChange={this.handleDOBChange}
                                onBlurInput={this.handleOnBlurDOB}
                                onKeyDownInput={this.handleKeyDown}
                                value={this.state.DOB}
                                maxLength={10}
                                dobFormat={
                                  this.state.utilityLabel1.utilityLabel.dobFormat
                                }
                                errMsg={parse(
                                  this.state.utilityLabel1.utilityLabel
                                    .errValidationDOB
                                )}
                                errorCC={this.state.error}
                                ccValid={this.state.dobValid}
                                ariaDscBy={
                                  this.state.dobValid
                                    ? "eca-confirm-dob-help"
                                    : "eca-confirm-dob-help dob-helper-text"
                                }
                              ></Textbox>

                              <div
                                className="form-text field-help"
                                id="eca-confirm-dob-help"
                              >
                                {this.props.productType === "gpcc"  || this.props.productType === "onekey"  || this.props.productType === "onekeyplus"  ?
                                parse(
                                  this.state.utilityLabel1.utilityLabel
                                    .lblDOBMsgFusionContent
                                ):  parse(
                                  this.state.utilityLabel1.utilityLabel
                                    .lblDOBMsgContent
                                )
                                }
                </div>
              </div>
            </div>
            <div
              className={
                              this.checkforFusionStyle()
                              ? "row m-0 eca-confirm-dobssn-container"
                              : "row m-0  eca-confirm-dobssn-container displayNoneDiv"
                            }
                          >
                            <div className= {this.checkforPartner()
                            ? "col-12 col-lg-8 offset-lg-2 py-3 px-":"col-12 col-sm-7 col-md-8 col-lg-7 pb-3 px-1"}>
                              <PasswordTextbox
                                inputLabel={
                                  this.state.utilityLabel1.utilityLabel.inputLabelSSN
                                }
                                inputMaxLength={4}
                                field={"ssn"}
                                inputType="text"
                                id="passwordId"
                                onKeyDownInput={this.handleKeyDown}
                                handleChangeInput={this.handleChangeSSN}
                                value={this.state.ssn}
                                idName={
                                  this.state.utilityLabel1.utilityLabel.idSSNName
                                }
                                clsName={`${this.isValidData(this.state.ssnValid)
                                  }`}
                                errMsg={this.state.formErrors}
                                onBlurInput={this.handleOnBlurSSN}
                                ariaDscBy={
                                  this.state.formErrors.ssn.length === 0
                                    ? "eca-confirm-ssn-help"
                                    : "eca-confirm-ssn-help ssn-helper-text"
                                }
                ></PasswordTextbox>
                <div className="form-text field-help" id="eca-confirm-ssn-help">{parse(this.state.utilityLabel1.utilityLabel.lblSSNMsgContent)}
                </div>
              </div>
            </div>
            <div>
                              <ReactModalComponent
                                        isFusionStyle={this.checkforFusionStyle()} show={this.state.handleShow} handleClose={(event) => {this.setState({ handleShow: false });
                                          dataService.trackEvent("Cancel", event, this.state.sessionID).then();}} modalTitle={"Cancel"}
                                        language={this.props.language} footerVisible={true} buttonText={"Close"}
                                        entryPoint={this.props.entryPoint} eventID={this.props.eventID}
                                        sessionID={this.props.sessionID} productType={this.state.productType}
                                        trackEvents={this.trackEvents} switchVal={this.props.switchVal} ivrPhoneNumber={this.state.ivrPhoneNumber}
                                                      ></ReactModalComponent>
            </div>
            <div id="modalLibertyComponent">
                             { ( this.props.choiceConvModal === 'true' ) &&
                             <LibertyPopupComponent
                             effectiveDate={ moment( this.props.switchVal.effectiveDate ).utc().format('MMM DD') }
                             showModal={this.props.choiceConvModal === 'true' ? true : false}
                            ></LibertyPopupComponent>
                             }
            </div>
            <div className="row m-0 pt-2 eca-confirm-cta-desktop-container d-none d-lg-block">
                            <div className= {this.checkforPartner() ? "col-12 text-center":"col-12 py-3 text-right"}>
                              <ButtonComponent
                                lblButton={
                                 this.checkforPartner() ?
                                  this.state.utilityLabel1.utilityLabel.btnConfirm : this.state.utilityLabel1.utilityLabel.btnCancel
                                }
                                productType={this.props.productType}
                                style={{minWidth:"184px"}}
                                styleCSS= {this.checkforPartner() ? "btn btn-consumer m-0 mb-3 px-5 eca-btn-continue text-center" :
                    "btn btn-outline-secondary m-0 mx-3 px-lg-7 eca-btn-cancel"}
                                idName= {this.checkforPartner() ? "btnConfirm" :"btnCancel" }
                                propTitle={this.checkforPartner()
                    ? this.state.utilityLabel1.utilityLabel.btnConfirm : this.state.utilityLabel1.utilityLabel.btnCancel}
                                disableProp={this.checkforPartner() ? this.state.btnConfirmDisable : false}
                                onClickEvent={this.checkforPartner()? this.confirmOnClick: this.cancelOnClick}
                                ariaHaspopup={this.checkforPartner() ? "false": "true"}
                                onMouseDownEvent={this.checkforPartner() ? null :this.handleMouseDown}
                                eventID={this.checkforPartner() ? null : this.state.eventID}
                ></ButtonComponent>
                <ButtonComponent
                              lblButton={
                                this.checkforPartner() ?
                                this.state.utilityLabel1.utilityLabel.btnCancel : this.state.utilityLabel1.utilityLabel.btnConfirm
                              }
                                productType={this.props.productType}
                                style={{minWidth:"184px"}}
                                styleCSS= {this.checkforPartner()
                    ? "btn btn-secondary m-0 px-5 eca-btn-cancel text-center" : "btn btn-primary m-0 px-lg-7 eca-btn-continue"}
                                idName= {this.checkforPartner() ? "btnCancel" :"btnConfirm" }
                                propTitle={this.checkforPartner()
                    ? this.state.utilityLabel1.utilityLabel.btnCancel : this.state.utilityLabel1.utilityLabel.btnConfirm}
                                onClickEvent={this.checkforPartner() ? this.cancelOnClick :this.confirmOnClick}
                                disableProp={this.checkforPartner() ? false : this.state.btnConfirmDisable}
                              ></ButtonComponent>
              </div>
            </div>
            <div className="row m-0 pt-1 pt-sm-2 eca-confirm-cta-mobile-container d-lg-none">
                            <div className={this.checkforPartner() ? "col-12 text-center":"col-12 col-sm-6 order-sm-last"}>
                              <ButtonComponent
                                lblButton={
                                  this.state.utilityLabel1.utilityLabel.btnConfirm
                                }
                                productType={this.props.productType}
                                style={{minWidth:"184px"}}
                                language={this.props.language}
                                idName="btnConfirm"
                                styleCSS= {this.checkforPartner()
                    ? "btn btn-consumer m-0 mb-3 px-5 eca-btn-continue" : "btn btn-primary m-0 mb-3 btn-block eca-btn-continue"}
                  propTitle={this.state.utilityLabel1.utilityLabel.btnConfirm} onClickEvent={this.confirmOnClick} disableProp={this.state.btnConfirmDisable}
                ></ButtonComponent>
              </div>
                            <div className={this.checkforPartner() ? "col-12 text-center":"col-12 col-sm-6"}>
                              <ButtonComponent
                                lblButton={
                                  this.state.utilityLabel1.utilityLabel.btnCancel
                                }
                                productType={this.props.productType}
                                style={{minWidth:"184px"}}
                                styleCSS= {this.checkforPartner()
                    ? "btn btn-secondary m-0 px-5 eca-btn-cancel" : "btn btn-outline-secondary m-0 btn-block eca-btn-cancel"}
                  idName="btnCancel" propTitle={this.state.utilityLabel1.utilityLabel.btnCancel} disableProp={false}
                  onClickEvent={this.cancelOnClick} ariaHaspopup="true" onMouseDownEvent={this.handleMouseDown} eventID={this.state.eventID}
                ></ButtonComponent>
              </div>
            </div>


    </>);
}


}



