import React from "react";
import parse from "html-react-parser";

function LibertySorryComponent(props) {

  return (
    <React.Fragment>
                  <p className="eca-switchoff-summary1">
                    {parse(props.utilityLabel1.utilityLabel.switchoffSummary1)}
                    <span style={{ whiteSpace: "nowrap" }}>
                      <a
                        href={
                          props.utilityLabel1.utilityLabel.switchoffPhoneHref
                        }
                        id="IVRPhone"
                        onClick={() =>
                          props.trackEvents("IVRSorry", "IVRPhone", props.sessionID)
                        }
                        className="underlined"
                        title="IVRPhone"
                      >
                        <u>{props.propIvrPhone}</u>
                      </a>
                      .
                    </span>
                  </p>

                  <p className="eca-switchoff-summary2">
                    {parse(props.utilityLabel1.utilityLabel.switchoffSummary2)}
                    <a href={props.utilityLabel1.utilityLabel.hrefEnroll}
                      id="enroll"
                      onClick={() => props.trackEvents("IVRSorry", "Enroll", props.sessionID)}
                      className="underlined"
                      title="Click this link to navigate Enroll page."
                    >
                      {props.utilityLabel1.utilityLabel.enrollNowThankyou}
                    </a>
                    {" "}
                    {props.utilityLabel1.utilityLabel.or}
                    <a href={props.utilityLabel1.utilityLabel.hrefSignOn}
                      id="signOn"
                      onClick={() => props.trackEvents("IVRSorry", "SignOn", props.sessionID)}
                      className="underlined"
                      title="Click this link to navigate SignOn page."
                    >
                      {props.utilityLabel1.utilityLabel.signOnSorry}
                    </a>
                  </p>
                </React.Fragment>
  );
}

export default LibertySorryComponent;
