import React, { Component } from "react";

class GPCCHeader extends Component {
  constructor(props) {
    super(props);
  }

  handleError(e) {
    e.target.onerror = null;
    e.target.src =
      require("../../../global/img/wf/masthead-wf-logo-211x22.png");
  }

  render() {
    return (
      <banner className="header-wrapper">
        <div className="container-masthead bg-red border-md-bottom-4 border-0 border-yellow">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 text-center text-md-left">
                <span className="d-block wf-logo">
                  <img
                    id="imgLogo"
                    src={require("../../../global/img/wf/masthead-wf-logo-879x92.svg").default}
                    alt="Wells Fargo Bank, N.A."
                    onError={this.handleError}
                    className="img-fluid"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        </banner>
    );
  }
}

export default GPCCHeader;
