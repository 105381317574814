export const utilityCommonLabel = {
  //short.landing.js
  lblConfirm: 'Confirm <span style="white-space:nowrap;">your card</span>',
  lblConfirmContent:
    'We need some information to confirm that you’ve received <span style="white-space:nowrap;">your card.</span>',
  lblFieldRequired: "All fields are required.",
  //card number
  inputLabelCC: "Credit card number",
  lblCCMsgContent:
    'Enter the full account number on <span style="white-space:nowrap;">your card.</span>',
  errValidationCCNumber:
    'Please enter a 16-digit credit <span style="white-space:nowrap;">card number.</span>',
  //amex nonamex img path
  ccAmexImgPath: require("../../global/img/card-cvv.svg").default,
  ccNonAmexImgPath: require("../../global/img/card-cvc-mc.svg").default,
  imgAmexCaption: "Credit card security code",
  imgNonAMexCaption: "CVC example",
  //cvv
  inputLabelCVVAmex: "Credit card security code",
  inputLabelCVVNonAmex: "Credit card CVC",
  lblCVVAmexMsgContent:
    'Enter the 3-digit CVC on the back of your card to the right of the <span style="white-space:nowrap;">expiration date.</span>',
  lblCVVNonAmexMsgContent:
    'Enter the 3-digit CVC on the back of your card to the right of the <span style="white-space:nowrap;">expiration date.</span>',
  errValidationCVVAmex:
    'Please enter a <span style="white-space:nowrap;">3-digit CVC.</span>',
  errValidationCVVNonAmex:
    'Please enter a <span style="white-space:nowrap;">3-digit CVC.</span>',
  //dob
  inputLabelDOB: "Date of birth (mm/dd/yyyy)",
  lblDOBMsgContent:
    'Enter the account holder\'s date <span style="white-space:nowrap;">of birth.</span>',
  errValidationDOB:
    'Please enter the account holder’s date of birth formatted as 2-digit month/2-digit day/<span style="white-space:nowrap;">4-digit year.</span>',
  //ssn
  inputLabelSSN: "SSN or ITIN (last 4 digits)",
  lblSSNMsgContent:
    'Enter the last 4 digits of the account holder’s Social Security number or Individual Taxpayer <span style="white-space:nowrap;">Identification number.</span>',
  errValidationSSN:
    'Please enter <span style="white-space:nowrap;">4-digits.</span>',

  btnCancel: "Cancel",
  btnConfirm: "Confirm",
  cardTypeAemx: "AMEX",
  dobFormat: "##/##/####",
  cardTypeNonAMex: "NONAMEX",
  idCCName: "cardNumber",
  idCVVName: "cvv",
  idSSNName: "ssn",

  sorryHeader: "We're sorry",
  buttonClose: "close",

  //footer
  footer1Title: "Privacy, Cookies, Security &amp; Legal",
  footer2Title: "Notice of Data Collection",
  footer3Title: "General Terms of Use",
  footer4Title: "Ad Choices",
  footer5Title: "Report Fraud",
  footer6Title: "Sitemap",
  footer7Title: "About Wells Fargo",
  footer8Title: "",
  footer9Title: "Diversity and Accessibility",
  footer10Title: "Home",
  footer1Href: "https://www.wellsfargo.com/privacy-security/",
  footer2Href:
    "https://www.wellsfargo.com/privacy-security/notice-of-data-collection/",
  footer3Href: "https://www.wellsfargo.com/privacy-security/terms/",
  footer4Href:
    "https://www.wellsfargo.com/privacy-security/privacy/online/#adchoices",
  footer5Href: "https://www.wellsfargo.com/privacy-security/fraud/report/",
  footer6Href: "https://www.wellsfargo.com/sitemap",
  footer7Href: "https://www.wellsfargo.com/about/?linkLoc=footer",
  footer8Href: "",
  footer9Href: "https://www.wellsfargo.com/about/diversity/",
  footer10Href: "https://www.wellsfargo.com/",
  footerContent:
    ' <ul className="list-inline mastfoot-links mb-0">  <li className="list-inline-item">       ' +
    '<a href="https://www.wellsfargo.com/privacy-security/" title="Privacy, Cookies, Security and Legal">Privacy, Cookies, Security &amp; Legal</a>  </li>  ' +
    '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       <a href="https://www.wellsfargo.com/privacy-security/notice-of-data-collection/" ' +
    'title="Notice of Data Collection">Notice of Data Collection</a>  </li>  <li className="list-inline-item divider">|</li>  <li className="list-inline-item">       ' +
    '<a href="https://www.wellsfargo.com/privacy-security/terms/" title="General Terms of Use">General Terms of Use</a>  </li>  <li className="list-inline-item divider">|</li>  ' +
    '<li className="list-inline-item">       <a href="https://www.wellsfargo.com/privacy-security/privacy/online/#adchoices" title="Ad Choices">Ad Choices</a>  </li>  ' +
    '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       ' +
    '<a href="https://www.wellsfargo.com/privacy-security/fraud/report/" title="Report Fraud">Report Fraud</a>  </li>  ' +
    '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       <a href="https://www.wellsfargo.com/sitemap" title="Sitemap">Sitemap</a>  </li>  ' +
    '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">      ' +
    '<a href="https://www.wellsfargo.com/about/?linkLoc=footer" title="About Wells Fargo">About Wells Fargo</a></li></ul>' +
    '<ul className="list-inline mastfoot-links mb-0">  ' +
    '<li className="list-inline-item">' +
    '<a href="https://www.wellsfargo.com/about/diversity/" title="Diversity and Accessibility">Diversity and Accessibility</a></li>' +
    '<li className="list-inline-item divider">|</li><li className="list-inline-item"><a href="https://www.wellsfargo.com/" title="Home">Home</a></li></ul>',
  footer11Title: "1999-",
  footer12Title: new Date().getFullYear(),
  footer13Title: " Wells Fargo. All rights reserved. NMLSR ID 399801"
};
