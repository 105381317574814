import React from "react";
import parse from "html-react-parser";

function GPCCSorryComponent(props) {
  return (
    <React.Fragment>
         <div className="row eca-terminate-system-container">
          <div className="col-12 text-center pb-2 pb-md-4">
            <h1 className="eca-terminate-system-title text-center pb-2 pb-md-0 pt-0 pt-sm-1">
            {parse(props.utilityLabel1.utilityLabel.switchoffSummary4)}
            </h1>
              <p className="eca-terminate-system-summary1 text-left text-md-center pt-3">
                {parse(props.utilityLabel1.utilityLabel.switchoffSummary5)}
                <span style={{ whiteSpace: "nowrap" }}>
                      <a
                        href={
                          props.utilityLabel1.utilityLabel.switchoffPhoneHref
                        }
                        id="IVRPhone"
                        onClick={() =>
                          props.trackEvents("IVRSorry", "IVRPhone", props.sessionID)
                        }
                        className="underlined"
                        title="IVRPhone"
                      >
                        <u>{props.propIvrPhone}</u>
                      </a>
                      .
                    </span>
                </p>
          </div>
        </div>
        </React.Fragment>
);
}
export default GPCCSorryComponent;

