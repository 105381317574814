import parse from "html-react-parser";
import React from "react";

function RubiconSorryComponent(props) {
  let utilityLabel1 = require("../resources/resources.rubicon");
  return (
        <div className="row eca-terminate-system-container">
          <div className="col-12 text-center pb-2 pb-md-4">
            <h1 className="eca-terminate-system-title text-center pb-2 pb-md-0 pt-0 pt-sm-1">
            {parse(utilityLabel1.utilityLabel.switchoffTitle)}
            </h1>
              <p className="eca-terminate-system-summary1 text-left text-md-center pt-3">
                {parse(utilityLabel1.utilityLabel.switchoffSummary1)}
                </p>
              <p className="eca-terminate-system-summary2 text-left text-md-center pt-2">
                {parse(utilityLabel1.utilityLabel.switchoffSummary2)}
                </p>
                <span className="logo">
                  <img
                    id="imgPhoneIcon"
                    src={require("../../global/img/wf/wf_icon_phone_landline_wf_gray_1.png")}
                    alt=""
                    className="img-fluid mt-3"
                    style={{ width: "100%", maxWidth: "40px" }}
                  />
                </span>
          </div>
        </div>
  );
}

export default RubiconSorryComponent;
