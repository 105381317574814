import { useEffect } from "react";
import parse from "html-react-parser";
import React from "react";
import BiltFraudComponent from "./biltfraud";
import GPCCFraudComponent from "./gpccfraud";
import ChoiceFraudComponent from "./choicefraud";
import RubiconFraudComponent from "./rubiconFraudComponent";
import OnekeyFraudComponent from "./onekeyfraud";
import * as utility from "../../utilities/utils";

function FraudComponent(props) {
  utility.getTitleConfirmationError();
  const productType = props.productType;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const getFraudComponent = (productType) => {
    switch (productType) {
      case "bilt":
        return (
          <BiltFraudComponent {...props} />
        );
      case "choice":
        return (
          <ChoiceFraudComponent {...props} />
        );
      default:
        return (
          <GPCCFraudComponent {...props} />
        );
    }
  };

  if(productType ==="rubicon"){
    return <RubiconFraudComponent {...props} />
  }
    else if(productType ==="onekey"||productType ==="onekeyplus"){
      return <OnekeyFraudComponent {...props} />
  }else if(productType === "gpcc" || productType === "hcom"){
    return (
      <div className="row eca-terminate-fraud-container">
          <div className="col-12 text-center pb-2 pb-md-4">
            <h1 className="eca-terminate-title text-center text-divider-yellow text-divider-yellow-md-0 pb-2 pb-md-0 pt-0 pt-sm-1">
              {parse(props.utilityLabel1.utilityLabel.terminateTitleFusion)}
            </h1>
            <p className="eca-terminate-system-summary1 text-left text-md-center pt-3">
              {parse(props.utilityLabel1.utilityLabel.terminateSummary5)}
            </p>

          </div>
        </div>
    )
  }else{
    return (
      <div>
        <div className="row pagetitle-container">
          <div className="col-12 text-center pb-2 pb-md-4">
            <h1 className="eca-terminate-title text-center text-divider-yellow text-divider-yellow-md-0 pb-2 pb-md-0 pt-0 pt-sm-1">
              {parse(props.utilityLabel1.utilityLabel.terminateTitle)}
            </h1>
          </div>
        </div>

        <div className="row eca-container">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="card eca-terminate-container border-0 shadow-0 shadow-md">
              <a name="eca-page-top"></a>
              <div className="card-body border-top-0 mb-0 p-md-5">
                <div className="row m-0 eca-terminate-summary-container">
                  <div className="col-12 mt-md-3">
                    {getFraudComponent(productType)}
                    <p className="eca-terminate-summary3">
                      {parse(props.utilityLabel1.utilityLabel.terminateSummary3)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FraudComponent;
