import parse from "html-react-parser";
import React from "react";
import Header from "../shared/controls/header";
import Footer from "../shared/controls/footer";
import * as utility from "../../utilities/utils";
import imageToRender from '../../global/img/wf/icon_checkmark_approved.png';
import { ServiceApi } from '../../environment';


function UmbrellaThankyouComponent(props) {
  utility.getTitleConfirmationSuccess();
  let params = utility.getParams();
  let utilityLabel1 = "";
  if (params.language === "es")
    utilityLabel1 = require("../resources/resources.es");
  else
    utilityLabel1 = require("../resources/resources.en");


  return (
    <div className="d-flex flex-column h-100 dce-bgpage  pace-done" id="umbrellaPage">
    <Header />
   <main className="main-wrapper flex-shrink-0 subtitle-0">
    <div className="container my-3">
      <div className="row pagetitle-container">
        <div className="col-12 text-center pb-2 pb-md-4">
          <span className="logo">
            <img
              id="imgUmbrellaCheckmark"
              src={imageToRender}
              alt=""
              className="img-fluid"
              style={{ width: "100%", maxWidth: "88px" }}
            />
          </span>
          <h1 className="eca-confirm-title text-center text-400 pb-2 pb-md-0 pt-3">
            {parse(utilityLabel1.utilityLabel.thankyouPage2Message5)}</h1>
        </div>
      </div>
      <div className="row cta-container" id="umbrellaButtons">
      <div className="col-12 text-center">
          <a
            type="button" role="button" className="btn btn-primary m-0 mb-3 w-auto d-inline-block eca-btn-signon1" style={{minWidth:"140px"}}
            onClick={() => { utility.trackEventsForRedirect("Thankyou", utilityLabel1.utilityLabel.signOn, props.sessionID,
            (params.language === "es" ? ServiceApi.SIGN_ON_ACCT_SPN_URL:ServiceApi.SIGN_ON_ACCT_ENG_URL))}}
            id="btnUmbrellaSignOn"
            tabIndex="0"
          >
            {utilityLabel1.utilityLabel.signOn}
          </a>
        </div>
        <div className="col-12 text-center">
          <a
            type="button" role="button" className="btn btn-secondary m-0 mb-3 w-auto d-inline-block eca-btn-signon2 mt-0" style={{minWidth:"140px"}}
            onClick={() => { utility.trackEventsForRedirect("Thankyou", utilityLabel1.utilityLabel.enrollNowBtn, props.sessionID,
            (params.language === "es" ? ServiceApi.ENROLLMENT_SPN_URL :ServiceApi.ENROLLMENT_ENG_URL))}}
            id="btnUmbrellaEnrollNow"
            tabIndex="0"
          >
            {utilityLabel1.utilityLabel.enrollNowBtn}
          </a>
        </div>
      </div>

    </div>
   </main>
    <Footer />
  </div>
);
}

export default UmbrellaThankyouComponent;
