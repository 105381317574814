import parse from "html-react-parser";
import React from "react";

function BiltFraudComponent(props) {

    return (
        <React.Fragment>
          <p className="eca-terminate-summary2">
            {parse(props.utilityLabel1.utilityLabel.terminateSummary2)}
            <span style={{ whiteSpace: "nowrap" }}>
              <a
                href={
                  props.utilityLabel1.utilityLabel.switchoffPhoneHref
                }
                id="IVRPhone"
                onClick={(e) =>
                  props?.trackEvents("FraudSorry", "IVRPhone", props.sessionID)
                }
                className="underlined"
                title="IVRPhone"
              >
                <u>{"1-800-723-5533"}</u>
              </a>
              .
            </span>
          </p>

          <p className="eca-terminate-summary1">
            {parse(props.utilityLabel1.utilityLabel.terminateSummary1)}
            <a href={props.utilityLabel1.utilityLabel.hrefEnroll}
              className="underlined"
              id="enroll"
              onClick={(e) => {
                props?.trackEvents("FraudSorry", "Enroll", props.sessionID);
              }}
              title="Click this link to navigate Enroll page."
            >
              {props.utilityLabel1.utilityLabel.enrollNowThankyou}
            </a>{" "}
            {props.utilityLabel1.utilityLabel.or}{" "}
            <a href={props.utilityLabel1.utilityLabel.hrefSignOn}
              className="underlined"
              id="signOn"
              onClick={(e) => {
                props?.trackEvents("FraudSorry", "SignOn", props.sessionID);
              }}
              title="Click this link to navigate SignOn page."
            >
              {props.utilityLabel1.utilityLabel.signOnSorry}
            </a>{" "}
          </p>
        </React.Fragment>
    );
}

export default BiltFraudComponent;
