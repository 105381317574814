import React, { Component } from "react";

class UmbrellaHeader extends Component {
  constructor(props) {
    super(props);
  }

  handleError(e) {
    e.target.onerror = null;
    e.target.src =
      require("../../../global/img/wf/masthead-onekey-wf-354x28.png");
  }

  render() {
    return (
      <banner className="header-wrapper">
        <div className="container-masthead bg-red border-md-bottom-4 border-yellow">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 text-center text-md-left">
                <span className="d-block wf-logo">
                  <img
                    id="imgUmbrellaLogo"
                    src={
                      require("../../../global/img/wf/masthead-onekey-wf-1249x90.png")                       
                    }
                    alt="Wells Fargo Bank, N.A."
                    onError={this.handleError}
                    className="img-fluid"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        </banner>
    );
  }
}

export default UmbrellaHeader;
