import {utilityCommonLabel} from "./resources.common";
const utilityRubiconLabel = {
    //short.landing.js
  lblConfirm: 'Confirm <span style="white-space:nowrap;">this card</span>',
  lblFusionConfirm:'Confirm <span style="white-space:nowrap;">this card</span>',
  lblConfirmContent:
    'Enter the DOB and last four of the SSN/ITIN for the cardholder, account owner, or administrator. All fields <span style="white-space:nowrap;"> are required.</span>',
  lblFieldRequired: "",
  lblFusionConfirmContent:
  'Enter the DOB and last four of the SSN/ITIN for the cardholder, account owner, or administrator. All fields <span style="white-space:nowrap;"> are required.</span>',
  //card number
  inputLabelCC: "Credit card number",
  lblCCMsgContent: 'Enter the full account number on <span style="white-space:nowrap;">your card.</span>',
  errValidationCCNumber:
    'Please enter a 16-digit credit <span style="white-space:nowrap;">card number.</span>',
  //amex nonamex img path
  ccAmexImgPath: "",
  ccNonAmexImgPath: "",
  imgAmexCaption: "Credit card security code",
  imgNonAMexCaption: "",
  //cvv
  inputLabelCVVAmex: "Credit card security code",
  inputLabelCVVNonAmex1: "Security code",
  inputLabelCVVNonAmex: "Security code",
  securityCodeDesc: 'Your Security code is a 3-digit number specific to your card.<br /><br /><strong>Where is it located?</strong><br />',
  securityCodeDesc1: 'Mastercard: The 3-digit number is located on the back of your card.',
  securityCodeDesc2:'',
  securityCodeDescTitle: "What’s this?",
  imgClose: require("../../global/img/icon-close.png"),
  lblCVVAmexMsgContent:'',
  lblCVVNonAmexMsgContent:'',
  errValidationCVVAmex:
    'Please enter a <span style="white-space:nowrap;">3-digit security code.</span>',
  errValidationCVVNonAmex:
    'Please enter a <span style="white-space:nowrap;">3-digit security code.</span>',
  //dob
  inputLabelDOB: "Date of birth (mm/dd/yyyy)",
  lblDOBMsgContent:'',
  errValidationDOB:
    'Please enter the account holder’s date of birth formatted as 2-digit month/2-digit day/<span style="white-space:nowrap;">4-digit year.</span>',
  //ssn
  inputLabelSSN: "SSN (last 4 digits)",
  lblSSNMsgContent:'',
  errValidationSSN:
    'Please enter <span style="white-space:nowrap;">4-digits.</span>',

  btnCancel: "Cancel",
  btnConfirm: "Confirm",
  cardTypeAemx: "AMEX",
  dobFormat: "##/##/####",
  cardTypeNonAMex: "NONAMEX",
  idCCName: "cardNumber",
  idCVVName: "cvv",
  idSSNName: "ssn",

  sorryHeader: "We're sorry",
  buttonClose: "close",

  //footer
  footer1Title: "Privacy, Cookies, Security &amp; Legal",
  footer2Title: "Notice of Data Collection",
  footer3Title: "General Terms of Use",
  footer4Title: "Ad Choices",
  footer5Title: "Report Fraud",
  footer6Title: "Sitemap",
  footer7Title: "About Wells Fargo",
  footer8Title: "",
  footer9Title: "Diversity and Accessibility",
  footer10Title: "Home",
  footer1Href: "https://www.wellsfargo.com/privacy-security/",
  footer2Href:
    "https://www.wellsfargo.com/privacy-security/notice-of-data-collection/",
  footer3Href: "https://www.wellsfargo.com/privacy-security/terms/",
  footer4Href:
    "https://www.wellsfargo.com/privacy-security/privacy/online/#adchoices",
  footer5Href: "https://www.wellsfargo.com/privacy-security/fraud/report/",
  footer6Href: "https://www.wellsfargo.com/sitemap",
  footer7Href: "https://www.wellsfargo.com/about/?linkLoc=footer",
  footer8Href: "",
  footer9Href: "https://www.wellsfargo.com/about/diversity/",
  footer10Href: "https://www.wellsfargo.com/",
  footerContentFusion:
  ' <ul className="mastfoot-ada-links">  <li>       ' +
  '<a href="https://www.wellsfargo.com/privacy-security/" title="Privacy, Cookies, Security and Legal">Privacy, Cookies, Security &amp; Legal</a>  </li>  ' +
  '  <li>       <a href="https://www.wellsfargo.com/privacy-security/notice-of-data-collection/" ' +
  'title="Notice of Data Collection">Notice of Data Collection</a>  </li>  <li>       ' +
  '<a href="https://www.wellsfargo.com/privacy-security/terms/" title="General Terms of Use">General Terms of Use</a>  </li>  ' +
  '<li>       <a href="https://www.wellsfargo.com/privacy-security/privacy/online/#adchoices" title="Ad Choices">Ad Choices</a>  </li>  ' +
  '  <li>       ' +
  '<a href="https://www.wellsfargo.com/privacy-security/fraud/report/" title="Report Fraud">Report Fraud</a>  </li>  ' +
  '  <li>       <a href="https://www.wellsfargo.com/sitemap" title="Sitemap">Sitemap</a>  </li>  ' +
  '  <li>      ' +
  '<a href="https://www.wellsfargo.com/about/?linkLoc=footer" title="About Wells Fargo">About Wells Fargo</a></li></ul>' +
  '<ul className="mastfoot-ada-links">  ' +
  '<li>' +
  '<a href="https://www.wellsfargo.com/about/diversity/" title="Diversity and Accessibility">Diversity and Accessibility</a></li>' +
  '<li><a href="https://www.wellsfargo.com/" title="Home">Home</a></li></ul>',
  footerContent:
    ' <ul className="list-inline mastfoot-links mb-0">  <li className="list-inline-item">       ' +
    '<a href="https://www.wellsfargo.com/privacy-security/" title="Privacy, Cookies, Security and Legal">Privacy, Cookies, Security &amp; Legal</a>  </li>  ' +
    '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       <a href="https://www.wellsfargo.com/privacy-security/notice-of-data-collection/" ' +
    'title="Notice of Data Collection">Notice of Data Collection</a>  </li>  <li className="list-inline-item divider">|</li>  <li className="list-inline-item">       ' +
    '<a href="https://www.wellsfargo.com/privacy-security/terms/" title="General Terms of Use">General Terms of Use</a>  </li>  <li className="list-inline-item divider">|</li>  ' +
    '<li className="list-inline-item">       <a href="https://www.wellsfargo.com/privacy-security/privacy/online/#adchoices" title="Ad Choices">Ad Choices</a>  </li>  ' +
    '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       ' +
    '<a href="https://www.wellsfargo.com/privacy-security/fraud/report/" title="Report Fraud">Report Fraud</a>  </li>  ' +
    '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       <a href="https://www.wellsfargo.com/sitemap" title="Sitemap">Sitemap</a>  </li>  ' +
    '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">      ' +
    '<a href="https://www.wellsfargo.com/about/?linkLoc=footer" title="About Wells Fargo">About Wells Fargo</a></li></ul>' +
    '<ul className="list-inline mastfoot-links mb-0">  ' +
    '<li className="list-inline-item">' +
    '<a href="https://www.wellsfargo.com/about/diversity/" title="Diversity and Accessibility">Diversity and Accessibility</a></li>' +
    '<li className="list-inline-item divider">|</li><li className="list-inline-item"><a href="https://www.wellsfargo.com/" title="Home">Home</a></li></ul>',
  footer11Title: "1999-",
  footer12Title: new Date().getFullYear(),
  footer13Title: " Wells Fargo. All rights reserved. NMLSR ID 399801",
  footer13TitleFusion: ' Wells Fargo. NMLSR ID 399801',

   // Rubicon Thank you Page
  thankyouPageMessage1: 'Congratulations!',
  thankyouPageMessage2: 'This card is ready',
  btnDone:'Done',
  hrefBtnDone:"https://www.wellsfargo.com/biz/",
  signOn: "Sign on",
  or: "or ",
  enrollNow: "enroll now.",
  hrefSignOn: "https://connect.secure.wellsfargo.com/auth/login/present?origin=cob&loginMode=jukePassword&serviceType=activateCard&LOB=CONS",
  hrefEnroll: "https://oam.wellsfargo.com/oamo/identity/enrollment?sourceApp=activateCard",

  //Session and cancel modal popup
  ecaErrorMessage1:
    '<p className="eca-error-msg1"> Your session has timed out. Please try to confirm your <span style="white-space:nowrap;">card again.</span></p>',
  continueBtn: "Continue",
  idleModalTitle: "Do you want to continue your session?",
  idleModalMsg1: "For security reasons, your session will time out at ",
  idleModalMsg2: " unless you choose to continue.",
  cancelTitle: "Cancel",
  cancelMsg1: "Are you sure you want to leave?",
  cancelMsg2: "If you cancel now, your card will not be confirmed.",
  cancelMsg3: "If you're a Wells Fargo Online",
  cancelMsg4: "customer, you can confirm online anytime. ",
  cancelMsg5: "You can also confirm your card by calling the customer service number on the back of your card.",
  cancelMsg6: "For your security, please close your browser.",
  cancelMsgContent:
    "If you're a Wells Fargo Online<sup>&reg;</sup> customer, you can confirm online anytime. ",
  cancelExitBtn: "Cancel and Exit",
  goBackBtn: "Go Back",
  closeDialog: "Are you sure you want to leave?",
  //fraud.js
  terminateTitle: 'Unfortunately, we can’t <span style="white-space:nowrap;">confirm your card.</span>',
  terminateSummary1: 'You can confirm your card by calling the customer service number on the back of your card.',
  terminateSummary2: 'For your security, please close <span style="white-space:nowrap;">your browser.</span>',
  // Rubicon Sorry Page systemerror.js
  switchoffTitle: 'System Unavailable',
  switchoffSummary1: 'You can confirm your card by calling the customer service number on the back of your card.',
  switchoffSummary2: 'For your security, please close your browser.',
  switchoffPhoneTitle: '1-833-441-0792',
  switchoffPhoneHref: 'tel:18334410792',
  //customerservice.js
  customerServiceTitle:
    'Your card is <span style="white-space:nowrap;">not confirmed</span>',
  customerServiceSummary1:
    "If you need help confirming your card, you can give us a call at the customer service number located on the back of" +
    '<span style="white-space:nowrap;">your card.</span>',
  customerServiceSummary2:
    'For your security, please close <span style="white-space:nowrap;">your browser.</span>',
  //message for 3 failed attempt
  warningMsgforFailedAttempt: '<p className="eca-error-msg1">We can’t verify some of the details you entered.Please <span style="white-space:nowrap;">try again.</span></p>',
};

export const utilityLabel={...utilityCommonLabel,...utilityRubiconLabel};

