import parse from "html-react-parser";
import React from "react";
import * as utility from "../../utilities/utils";
function OnekeyFraudComponent(props) {
  utility.getTitleConfirmationError();
  return (
    <div className="row eca-terminate-fraud-container">
      <div className="col-12 text-center pb-2 pb-md-4">
            <p className="eca-terminate-system-summary1 text-left text-md-center pt-2">
            {parse(props.utilityLabel1.utilityLabel.onekeyterminateSummary2)}
            <span style={{ whiteSpace: "nowrap" }}>
          <a
            href={
              props.utilityLabel1.utilityLabel.switchoffPhoneHref
            }
            id="IVRPhone"
            onClick={(e) =>
              props?.trackEvents("FraudSorry", "IVRPhone", props.sessionID)
            }
            className="underlined"
            title="IVRPhone"
          >
            <u>{props.ivrPhone}</u>
          </a>
          .
        </span>
            </p>
      </div>
    </div>
);
}
export default OnekeyFraudComponent;