import React from "react";
import parse from 'html-react-parser';
import * as utility from "../../../utilities/utils";


function findUtilityLabelFromProduct (partner, language) {

     let utilityLabel1 ="";
     if (partner === "bilt" || partner === "choice" ) {
       utilityLabel1 = require("../../resources/resources.casa");
     }else if (partner === "rubicon") {
          utilityLabel1 = require("../../resources/resources.rubicon");
     }else if (language === "es") {
       utilityLabel1 = require("../../resources/resources.es");
     }else{
          utilityLabel1 = require("../../resources/resources.en");
     }
     return utilityLabel1;
   }

function Footer(props) {
     let params = utility.getParams();
     let prodType = utility.getPartner();

     let utilityLabel1 =  findUtilityLabelFromProduct(prodType, params.language);
     let footerContent = utilityLabel1.utilityLabel.footerContent;
     let footer13Title = utilityLabel1.utilityLabel.footer13Title
     let copyRight = "Copyright";
     if(prodType === 'gpcc' || prodType === 'attune' || prodType === 'rubicon' || prodType === "onekey" || prodType === "onekeyplus") {
          footerContent = utilityLabel1.utilityLabel.footerContentFusion;
          footer13Title = utilityLabel1.utilityLabel.footer13TitleFusion;
          copyRight = "";
     }
     return (
          <footer className="footer-wrapper mt-auto bg-dgray">
               <div className="container-fluid container-mastfoot d-none d-lg-block">
                    <div className="container">
                         <div className="row no-gutters">
                              <div className="col-12">
                                   {parse(footerContent)}
                              </div>
                              <div className="col-12">
                                   <p className="mastfoot-copyright mt-3"><span lang="en">
                                        {copyRight}</span> &copy; {utilityLabel1.utilityLabel.footer11Title}
                                        <span className="currentyear">{utilityLabel1.utilityLabel.footer12Title}</span>
                                        {parse(footer13Title)}</p>
                              </div>
                         </div>
                    </div>
               </div>
               <div className="container-fluid container-mastfoot-mobile d-block d-lg-none" aria-hidden="true">
                    <div className="row no-gutters">
                         <div className="col-12">
                              <ul className="list-inline mastfoot-links">
                                   <li className="list-inline-item align-top">
                                        <a href="https://www.wellsfargo.com/privacy-security/"
                                             title={parse(utilityLabel1.utilityLabel.footer1Title)}>
                                             {parse(utilityLabel1.utilityLabel.footer1Title)}</a>
                                   </li>
                                   <li className="list-inline-item align-top">
                                        <a href="https://www.wellsfargo.com/sitemap"
                                             title={utilityLabel1.utilityLabel.footer2Title}>
                                             {utilityLabel1.utilityLabel.footer2Title}</a>
                                   </li>
                                   <li className="list-inline-item align-top">
                                        <a href="https://www.wellsfargo.com/privacy-security/notice-of-data-collection/"
                                             title={utilityLabel1.utilityLabel.footer3Title}>
                                             {utilityLabel1.utilityLabel.footer3Title}</a>
                                   </li>
                                   <li className="list-inline-item align-top">
                                        <a href="https://www.wellsfargo.com/about/?linkLoc=footer"
                                             title={utilityLabel1.utilityLabel.footer4Title}>
                                             {utilityLabel1.utilityLabel.footer4Title}</a>
                                   </li>
                                   <li className="list-inline-item align-top">
                                        <a href="https://www.wellsfargo.com/privacy-security/terms/"
                                             title={utilityLabel1.utilityLabel.footer5Title}>
                                             {utilityLabel1.utilityLabel.footer5Title}</a>
                                   </li>
                                   <li className="list-inline-item align-top">
                                        <a href="https://www.wellsfargo.com/about/careers/"
                                             title={utilityLabel1.utilityLabel.footer6Title}>
                                             {utilityLabel1.utilityLabel.footer6Title}</a>
                                   </li>
                                   <li className="list-inline-item align-top">
                                        <a href="https://www.wellsfargo.com/privacy-security/privacy/online/#adchoices"
                                             title={utilityLabel1.utilityLabel.footer7}>
                                             {parse(utilityLabel1.utilityLabel.footer7Title)}</a>
                                   </li>

                                   {props.productType === "bilt" || props.productType === "choice" ? "" : <li className="list-inline-item align-top">
                                        <a href="https://www.wellsfargo.com/about/diversity/"
                                             title={utilityLabel1.utilityLabel.footer8Title}>
                                             {utilityLabel1.utilityLabel.footer8Title}</a>
                                   </li>}
                                   <li className="list-inline-item align-top">
                                        <a href="https://www.wellsfargo.com/privacy-security/fraud/report/"
                                             title={utilityLabel1.utilityLabel.footer9Title}>
                                             {utilityLabel1.utilityLabel.footer9Title}</a>
                                   </li>
                                   <li className="list-inline-item align-top">
                                        <a href="https://www.wellsfargo.com/"
                                             title={utilityLabel1.utilityLabel.footer10Title}>
                                             {utilityLabel1.utilityLabel.footer10Title}</a>
                                   </li>
                              </ul>
                         </div>
                         <div className="col-12">
                              <p className="mastfoot-copyright">&copy;  {utilityLabel1.utilityLabel.footer11Title}
                                   <span className="currentyear"> {utilityLabel1.utilityLabel.footer12Title}</span>
                                   {parse(footer13Title)}</p>
                         </div>
                    </div>
               </div>
          </footer>
     );
}

export default Footer;
