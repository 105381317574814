import React from 'react';

function ButtonMainComponent(props) {
    return (
        <button
            className={props.styleCSS}
            title={props.propTitle}
            disabled={props.disableProp}
            onClick={props.onClickEvent}
            onMouseDown={props.onMouseDownEvent}
            aria-haspopup={props.ariaHaspopup}
            id={props.idName}
            style={props.style}>
            {props.lblButton}
        </button>
    );
}
export default ButtonMainComponent;
