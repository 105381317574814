import * as React from 'react';
import parse from "html-react-parser";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import * as utility from "../../../utilities/utils";

export default function PopoverPopupState(props) {
let utilityLabels = utility.findUtilityLabelFromProduct(props.productType, props.language);

  const getPopover = () => {
    const handleClick = () => {
      document.body.click();
      setTimeout(() => document.getElementById("linkRef").focus(), 100);
      };
  return (
    <Popover>
      <Popover.Title as="h3">
      <button autoFocus href="#" tabIndex="0" data-testid="btnClose"
       className='btn btn-sm btn-sm-p-0 btn-sm-m-0 title-close' 
      aria-label="Close ToolTip" onClick={handleClick}>X</button>
        {utilityLabels.utilityLabel.inputLabelCVVNonAmex}
      </Popover.Title>
      <Popover.Content>
        {parse(utilityLabels.utilityLabel.securityCodeDesc)}
        {parse(utilityLabels.utilityLabel.securityCodeDesc1)}
        {parse(utilityLabels.utilityLabel.securityCodeDesc2)}
      </Popover.Content>
    </Popover>
  )
  };
  return (
    <div>
      <OverlayTrigger
        placement="right"
        trigger="click"
        rootClose={true}
        overlay={getPopover()}>
        <a href="#"id="linkRef"role="button"aria-label="Opens the what's this dialog"className="d-inline-block balloon-help"variant="contained"
        data-testid="popover">{utilityLabels.utilityLabel.securityCodeDescTitle}</a>
      </OverlayTrigger>
    </div>
  );

  }