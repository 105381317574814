import React, { useEffect } from "react";
import Footer from "../shared/controls/footer";
import Header from "../shared/controls/header";
import CustomerServiceComponent from "./customerservice ";
import FraudComponent from "./fraud";
import SystemErrorComponent from "./systemerror";
import { trackEvent } from "../../services/data.service";
import * as utility from "../../utilities/utils";

function SorryPage(props) {
  utility.getTitleSystemUnavailable();
  useEffect(() => {

  }, []);

  var detailData = "en";
    var ivrPhone = "1-866-537-8424";
  var productType = "gpcc";
  var propEventID = "";
    var propSessionID ="";
   

 if(props.history.location.detail != null && props.history.location.detail !== undefined) {
    detailData = props.history.location.detail.language;
    ivrPhone = props.history.location.detail.ivrPhoneNumber;
    var errorCode =  props.history.location.detail.errorCode;
    productType = props.history.location.detail.productType;
    propEventID = props.history.location.detail.eventID;
     propSessionID =props.history.location.detail.sessionID
  }
  let utilityLabel1 = "";
  let language = "";
  if (detailData !== null || detailData !== undefined) {
    language = detailData;

    utilityLabel1 = utility.findUtilityLabelFromProduct(productType, language);
  }

  const trackEvents = (page, event, sessionID) => {
    if (event === "SignOn") {
      window.location.replace(utilityLabel1.utilityLabel.hrefSignOn);
    } else if (event === "Enroll") {
      window.location.replace(utilityLabel1.utilityLabel.hrefEnroll);
    }
    if(sessionID !== null && sessionID !== "" && event !==null && event !== "") {
      trackEvent( page, event,  sessionID).then();
    }
  };

  const getSorryContent = (type) => {
    switch (type) {
      case "FAIL":
        return (
          <FraudComponent
            pageLocation="failPage"
            language={language}
            utilityLabel1={utilityLabel1}
            trackEvents={trackEvents}
            ivrPhone={ivrPhone}
            errorCode={errorCode}
            productType={productType}
            sessionID={propSessionID}
          />
        );
      case "UNKNOWN":
        return (
          <SystemErrorComponent
            pageLocation="unkownPage"
            language={language}
            utilityLabel1={utilityLabel1}
            trackEvents={trackEvents}
            ivrPhone={ivrPhone}
            productType={productType}
            sessionID={propSessionID}
            eventID={propEventID}
          />
        );
      case "EXIT":
        return (
          <CustomerServiceComponent
            pageLocation="exitPage"
            language={language}
            utilityLabel1={utilityLabel1}
            sessionID={propSessionID}
          />
        );
      default:
        return (
          <SystemErrorComponent
            pageLocation="systemErrorPage"
            language={language}
            utilityLabel1={utilityLabel1}
            trackEvents={trackEvents}
            ivrPhone={ivrPhone}
            productType={productType}
            sessionID={propSessionID}
            eventID={propEventID}
          />
        );
    }
  };
    return (
      <div className={(productType ==="rubicon" || productType ==="gpcc") ? "d-flex flex-column h-100 dce-bgpage  pace-done":"d-flex flex-column h-100"}>
        <Header />
      <main className={(productType ==="rubicon" || productType ==="gpcc") ?
      "main-wrapper-sorry flex-shrink-0 subtitle-0 dce-bgpage  pace-done":"main-wrapper-sorry flex-shrink-0 subtitle-0"}>
          {props.history.location.detail !== null && props.history.location.detail !== undefined ? (
            <div className="container my-3">
              {getSorryContent(props.history.location.detail.type)}
            </div>
        ):
            (
              <div className="container my-3">
                {getSorryContent("ivr")}
              </div>
            )}
        </main>
        <Footer language={language} productType={productType} />
      </div>
    );
  }

export default SorryPage;
